<template>
  <div class="page-content vehicle-info">
    <div class="page-form content">
      <Form
        :ref="`form-vehicle`"
        :defaultForm.sync="form"
        :fields="fields"
        :defaultText.sync="fieldTexts"
      >
        <van-field
          slot="field-custom-vin"
          v-model.trim="form.vin"
          label="车架号"
          disabled
          input-align="right"
        >
          <template #button>
            <div
              v-if="!assessSuccess"
              :class="['field-btn', assessIng ? 'ing' : '']"
              @click="assessFn"
            >
              {{ assessIng ? "评估中 " : "去评估" }}
            </div>
            <span v-else class="field-btn suc">已评估</span>
          </template>
        </van-field>
      </Form>
    </div>

    <div class="page-btn btns">
      <van-button type="info" size="small" @click="submitFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  assessVehicle,
  createVehicleInfo,
  vehicleInfo,
  orderDetailInfo,
  assessChannel
} from "@/api/apply";
import { setFormData } from "../dev_form_json";
import { getSomeEnums } from "@/api/enums";
export default {
  components: {
    Form: () => import("@/components/Form/Index")
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      bizNo: "",
      assessIng: false,
      evalStatus: false,
      assessSuccess: false,
      isAccident: false, // 是否事故车
      form: {},
      enums: {},
      fieldTexts: {}
    };
  },
  computed: {
    fields() {
      let arr = [
        {
          type: "text",
          label: "车牌",
          disabled: true,
          key: "carPlateNum"
        },
        {
          type: "custom",
          label: "车架号",
          key: "vin"
        },
        {
          type: "text",
          label: "是否事故车",
          disabled: true,
          placeholder: "-",
          key: "accidentLevel"
        },
        {
          type: "text",
          label: "车辆评估金额",
          disabled: true,
          placeholder: "-",
          key: "carValue"
        },
        {
          type: "text",
          label: "申请金额",
          key: "creditApplyAmt",
          required: true,
          disabled: !this.assessSuccess,
          change: () => {
            this.$set(this.form, "loanUse", "");
            this.$set(this.fieldTexts, "loanUse", "");
          },
          placeholder: () => {
            const carValue = +this.form.carValue || 0;
            if (!this.assessSuccess) return "请先进行车辆评估";
            return `可申请金额 ${parseInt((carValue * 0.8) / 100) * 100}`;
          },
          validator: (v) => {
            const _v = +v;
            const carValue = +this.form.carValue || 0;
            const reg = /^\+?[1-9]\d*$/;
            const ableAmt = parseInt((carValue * 0.8) / 100) * 100;
            if (!reg.test(_v)) throw "请输入正整数";
            else if (_v > ableAmt) {
              throw `申请金额不能大于${ableAmt}`;
            } else return true;
          }
        },
        {
          type: "select",
          label: "贷款用途",
          required: true,
          disabled: !this.form.creditApplyAmt,
          key: "loanUse",
          placeholder: !this.form.creditApplyAmt
            ? "请先输入申请金额"
            : "请选择贷款用途",
          enums: (this.enums?.loanUseEnums || []).filter((e) => {
            let desc = e.description;
            if (desc) {
              const [x, y] = JSON.parse(e.description);
              return (
                this.form.creditApplyAmt > +x * 10000 &&
                this.form.creditApplyAmt <= +y * 10000
              );
            }
            return true;
          })
        },
        {
          type: "select",
          label: "融资行业投向",
          required: true,
          key: "financingIndustry",
          enums: this.enums?.industryEnums || []
        },
        {
          type: "text",
          label: "车辆颜色",
          required: true,
          key: "carColor"
        },
        // {
        //   type: "region",
        //   label: "车辆上牌地",
        //   required: true,
        //   namesKey: ["licenseProvinceName", "licenseCityName"],
        //   keys: ["licenseProvinceCode", "licenseCityCode"],
        //   key: "licenseCode"
        // },
        {
          type: "amount",
          label: "购买金额(元)",
          decimal: 2,
          required: true,
          key: "purchaseAmount"
        },
        {
          type: "text",
          label: "受理地",
          required: true,
          key: "acceptLocation"
        },
        {
          type: "number",
          label: "过户次数",
          required: true,
          key: "transferNumber"
        },
        {
          type: "number",
          label: "行驶里程(公里)",
          required: true,
          labelWidth: "7.2em",
          key: "workDistance",
          maxlength: 7,
          validator: (v) => {
            const reg = /^(?:0|(?:-?[1-9]\d*))$/;
            if (!v) throw `请输入行驶里程`;
            else if (v && +v < 0) throw `行驶里程大于等于零的数字`;
            else if (v && !reg.test(v)) throw `行驶里程请输入整数`;
            else return true;
          }
        },
        {
          type: "number",
          label: "一年内过户次数",
          labelWidth: "7.2em",
          required: true,
          key: "yearTransferNumber"
        },
        {
          type: "text",
          label: "车辆准载限制",
          required: true,
          key: "loadLimit"
        },
        {
          type: "select",
          label: "车辆是否解押",
          required: true,
          key: "releasePledge",
          enums: this.enums?.pledge || [],
          change: () => {
            this.$set(this.form, "releasePledgeDate", "");
            // if (value == 0) {
            //   this.$set(this.form, "releasePledgeDate", "");
            // } else {
            //   this.$set(this.form, "releasePledgeDate", "1970-01-01");
            // }
          }
        }
      ];
      if (this.form.releasePledge == 0) {
        arr.push({
          type: "date",
          label: "车辆解押日期",
          required: true,
          config: {
            maxDate: new Date(Date.now() - 1000 * 60 * 60 * 24)
          },
          key: "releasePledgeDate"
        });
      }

      return arr;
    }
  },
  mounted() {
    this.getAllEnums();
    this.init();

    window.agilityAutoFill = this.agilityAutoFill;
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.bizNo = data.bizNo || "";
      this.getAssessChannel();
      this.getApplyInfo();
    },
    async getAssessChannel() {
      try {
        const { data } = await assessChannel(this.bizNo);
        this.evalStatus = data.evalStatus == "Y";
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getAllEnums() {
      try {
        const [d1, d2, d3] = await Promise.all([
          getSomeEnums("pledge"),
          getSomeEnums("loanPurpose"),
          getSomeEnums("invest")
        ]);
        this.$set(this.enums, "pledge", d1);
        this.$set(this.enums, "loanUseEnums", d2);
        this.$set(this.enums, "industryEnums", d3);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 申请信息
    async getApplyInfo() {
      try {
        this.loading = true;
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await orderDetailInfo(this.bizNo);
        const {
          carPlateNum,
          vin,
          assessStatus,
          accidentLevel,
          carValue,
          vehicleInformationStatus
        } = data;
        this.$set(this.form, "carPlateNum", carPlateNum);
        this.$set(this.form, "vin", vin);
        if (accidentLevel)
          this.$set(
            this.form,
            "accidentLevel",
            [1, 2].includes(+accidentLevel) ? "是" : "否"
          );
        this.assessSuccess = assessStatus;
        if (assessStatus) {
          const v2carValue = this.$route.query.carValue;
          this.$set(this.form, "carValue", v2carValue || carValue || "");
        }

        if (vehicleInformationStatus) {
          this.getVehicleInfo();
        } else this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },
    async getVehicleInfo() {
      try {
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        const { data } = await vehicleInfo(this.bizNo);
        const keys = this.fields.map((e) => e.key);

        keys.map((k) => {
          if (data[k]) {
            if (k == "loanUse")
              this.$nextTick(() => {
                this.$set(this.form, k, data[k]);
              });
            else this.$set(this.form, k, data[k]);
          }
        });

        if (data.releasePledge == 0) {
          // 已解押 设置解押日期
          this.$nextTick(() => {
            this.$set(
              this.form,
              "releasePledgeDate",
              data["releasePledgeDate"]
            );
          });
        }
        this.$toast.clear();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async assessFn() {
      if (this.assessIng) {
        this.$errMsg("车辆评估中");
        return;
      }
      if (this.evalStatus) this.$router.push("/vehicle-assess");
      else {
        try {
          this.assessIng = true;
          await assessVehicle(this.bizNo);
          this.getApplyInfo();
        } catch (error) {
          this.$errMsg(error);
        } finally {
          this.assessIng = false;
        }
      }
    },
    async submitFn() {
      try {
        if (!this.assessSuccess) throw "请先发起评估";
        const infodom = this.$refs["form-vehicle"];
        await infodom.validate();
        const params = {
          bizNo: this.bizNo,
          ...this.form
        };
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true,
          duration: 0
        });
        await createVehicleInfo(params);
        this.$toast.clear();
        this.$router.push("/apply-detail");
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 开发快速调试
    agilityAutoFill() {
      let isdev = process.env.NODE_ENV === "development";
      if (isdev) {
        setFormData(this, "form", "apply_form_data_car_form");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./input-style.scss";
.vehicle-info {
  .field-btn {
    &.ing {
      color: #666;
    }
    &.suc {
      color: #52c41a;
    }
  }
  .btns {
    .van-button {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
